<template>
    <div class="fuel-history">
         <div class="order-history">
            <h2>{{$t('myHistory')}}</h2>
            <div v-for="(order, index) in orderHistory" :key="index" class="order">
                <div class="coupon-text">
                    <div class="order-header">
                        <span class="coupon-title">{{$t("historyTransactionNumber", {transactionNumber : order.authNumber})}}</span>
                        <span class="coupon-title">{{$d(new Date(order.date), 'long')}}</span>
                    </div>
                    <div v-if="order.type == 'fuel'" class="order-content order-type-fuel">
                        <div class="order-product">
                            <span class="fuel-type">{{order.orderContent.fuelType.label}}</span>
                            <span class="fuel-qty">{{order.orderContent.quantity}} L</span>
                        </div>
                        <span class="order-loyalty-points">{{$t("historyLoyaltyPointsEarned")}} : <span>{{order.orderContent.earnedLoyaltyPoints}}</span></span>
                        <span class="order-amount">{{$t("totalAmount")}} : <span>{{order.orderContent.amount.toFixed(2)}} €</span></span>
                    </div>
                    <div v-else-if="order.type == 'shop'" class="order-content order-type-shop">
                        <div v-for="(product, i) in order.orderContent.products" class="order-product">
                            <span class="fuel-type">{{product.qty}} x {{product.product[getKeyWithLang("name")]}}</span>
                            <span class="fuel-type">{{product.product.price.toFixed(2)}} €</span>
                        </div>
                        <span class="order-loyalty-points">{{$t("historyLoyaltyPointsEarned")}} : <span>{{order.orderContent.earnedLoyaltyPoints}}</span></span>
                        <span class="order-amount">{{$t("totalAmount")}} : <span>{{order.orderContent.amount.toFixed(2)}} €</span></span>
                    </div>
                    <div v-else-if="order.type == 'coupon'" class="order-content order-type-fuel">
                        <span class="fuel-type">1 x {{order.orderContent.product}}</span>
                        <span class="order-loyalty-points">{{$t("historyLoyaltyPointsUsed")}} : <span>{{order.orderContent.usedLoyaltyPoints}}</span></span>
                        <span class="order-amount">{{$t("totalAmount")}} : <span>{{order.orderContent.amount.toFixed(2)}} €</span></span>
                    </div>
                </div>
            </div>
            <div v-if="orderHistory.length == 0" class="no-order">
                {{$t("noOrderHisory")}}
            </div>
        </div>
    </div>
</template>
<script>
    var Stats = require("@/assets/js/Stats");
    export default {
        name: "History",
        data(){
            return {
                orderHistory : JSON.parse(localStorage.getItem("DKC_fuel_history")) || []
            }
        },
        mounted(){
            Stats.setStats({nbLoyaltyAccess: 1});
        },
        methods:{
            getKeyWithLang(key){
                let lang = this.$root.$i18n.locale;
                return key+ lang.charAt(0).toUpperCase() + lang.slice(1);
            }
        }
    }
</script>

<style>


</style>
